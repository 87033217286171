var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.topics,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","small":"","color":"blue","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC,
            params : {
              topicId : item.id,
              testId : _vm.testId
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр топика")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","small":"","color":"blue","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTIONS,
            params : {
              topicId : item.id,
              testId : _vm.testId
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-list ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр вопросов")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","depressed":"","small":"","color":"warning","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC_EDIT,
            params : {
              topicId : item.id,
              testId : _vm.testId
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование топика")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","depressed":"","small":"","color":"red"},on:{"click":function($event){return _vm.$emit('remove:topic', item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление топика")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }