<template>
  <VDataTable
    :headers="headers"
    :items="topics"
    :loading="loading"
  >
    <template #item.actions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="blue"
            class="mr-2"
            :to="{
              name : Names.R_TALENT_TEST_TOPIC,
              params : {
                topicId : item.id,
                testId : testId
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр топика</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="blue"
            class="mr-2"
            :to="{
              name : Names.R_TALENT_TEST_TOPIC_QUESTIONS,
              params : {
                topicId : item.id,
                testId : testId
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр вопросов</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="warning"
            :to="{
              name : Names.R_TALENT_TEST_TOPIC_EDIT,
              params : {
                topicId : item.id,
                testId : testId
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование топика</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="red"
            v-bind="attrs"
            @click="$emit('remove:topic', item)"
            v-on="on"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление топика</span>
      </VTooltip>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'TopicTable',
  inject: ['Names'],
  props: {
    testId: {
      type: [Number, String],
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Название',
          value: 'name',
        },
        {
          text: 'Topic Key',
          value: 'topicKey',
        },
        {
          text: 'Действия',
          align: 'right',
          value: 'actions',
        },
      ],
    };
  },
};
</script>
