var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить топик ")]),_c('VCardText',[_vm._v(" Топик "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.topic.id))]),_vm._v(" будет удален! "),_c('br'),_vm._v(" Действительно хотите удалить топик? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeTopic}},[_vm._v(" Да ")])],1)],1)],1),_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v(" Топики ")]),_c('VSpacer'),_c('VBtn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","to":{
              name : _vm.Names.R_TALENT_QUESTIONS_FILE_UPLOAD,
              params : {
                testId : _vm.testId
              }
            }}},[_c('VIcon',{staticClass:"mr-2"},[_vm._v(" fal fa-upload ")]),_c('span',[_vm._v("Загрузить из файла")])],1),_c('VBtn',{attrs:{"depressed":"","color":"primary","to":{
              name : _vm.Names.R_TALENT_TEST_TOPIC_CREATE,
              params : {
                testId : _vm.testId
              }
            }}},[_c('VIcon',{staticClass:"mr-2"},[_vm._v(" fal fa-plus ")]),_c('span',[_vm._v("Создать топик")])],1)],1),_c('VCardText',[_c('TopicTable',{attrs:{"test-id":_vm.testId,"topics":_vm.topics,"loading":_vm.loadingTopics},on:{"remove:topic":_vm.deleteTopic}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }