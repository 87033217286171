<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VDialog
            v-model="confirmDialog"
            max-width="500px"
            @click:outside="handleCancel"
          >
            <VCard>
              <VCardTitle class="headline">
                Удалить топик
              </VCardTitle>

              <VCardText>
                Топик <span class="red--text">{{ topic.id }}</span> будет удален! <br>
                Действительно хотите удалить топик?
              </VCardText>

              <VCardActions>
                <VSpacer />

                <VBtn
                  text
                  @click="handleCancel"
                >
                  Нет
                </VBtn>

                <VBtn
                  color="red darken-1"
                  text
                  @click="removeTopic"
                >
                  Да
                </VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Топики
            </VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              class="mr-2"
              :to="{
                name : Names.R_TALENT_QUESTIONS_FILE_UPLOAD,
                params : {
                  testId : testId
                }
              }"
            >
              <VIcon class="mr-2">
                fal fa-upload
              </VIcon>
              <span>Загрузить из файла</span>
            </VBtn>
            <VBtn
              depressed
              color="primary"
              :to="{
                name : Names.R_TALENT_TEST_TOPIC_CREATE,
                params : {
                  testId : testId
                }
              }"
            >
              <VIcon class="mr-2">
                fal fa-plus
              </VIcon>
              <span>Создать топик</span>
            </VBtn>
          </VToolbar>

          <VCardText>
            <TopicTable
              :test-id="testId"
              :topics="topics"
              :loading="loadingTopics"
              @remove:topic="deleteTopic"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TopicTable from '../../../../components/talent/test/topics/TopicTable.vue';

export default {
  name: 'TopicList',
  components: { TopicTable },
  inject: ['Names'],
  data() {
    return {
      topics: [],
      loadingTopics: false,
      topic: {},
      confirmDialog: false,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  async created() {
    await this.getTopics(this.testId);
  },
  methods: {
    async getTopics(testId) {
      this.loadingTopics = true;

      try {
        this.topics = (await this.$di.api.Talent.topicsGet({ testId })) || [];
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loadingTopics = false;
      }
    },
    async removeTopic() {
      try {
        const {
          topic: { id: topicId },
          testId,
        } = this;
        const data = { testId, topicId };

        await this.$di.api.Talent.topicDelete(data);
        await this.getTopics(this.testId);

        this.resetTopicEdit();

        this.$di.notify.snackSuccess('Топик успешно удалён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    resetTopicEdit() {
      this.confirmDialog = false;
      this.topic = {};
    },

    handleCancel() {
      this.resetTopicEdit();
    },

    deleteTopic(item) {
      this.confirmDialog = true;
      this.topic = { ...item };
    },
  },
};
</script>
